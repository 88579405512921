import {
  type ClientLoaderFunctionArgs,
  useLoaderData,
  useNavigate,
} from '@remix-run/react';
import { useMemo } from 'react';

import { fromAPIBlockTypes } from '@lp-lib/game/src/api-integration';

import {
  BrandEditor,
  type BrandFormData,
} from '../components/Brand/BrandEditor';
import { apiService } from '../services/api-service';
import {
  fromMediaDataDTO,
  fromMediaDTO,
  toMediaDataDTO,
} from '../utils/api-dto';
import { tokenWithRedirect } from '../utils/router';

export const clientLoader = async (action: ClientLoaderFunctionArgs) => {
  const { id } = action.params;
  if (!id) throw new Response('Not Found', { status: 404 });

  const resp = await tokenWithRedirect(
    () => apiService.brand.get(id),
    action.request.url,
    { admin: true }
  );

  return {
    brand: resp.data.brand,
  };
};

function AdminBrandEdit(): JSX.Element {
  const { brand } = useLoaderData<typeof clientLoader>();

  const navigate = useNavigate();

  const handleConfirm = async (data: BrandFormData) => {
    await apiService.brand.update(brand.id, {
      name: data.name,
      showcaseMediaData: toMediaDataDTO(data.showcaseMedia.data),
      showcaseText: data.showcaseText,
      blockLengthSec: data.blockLengthSec,
      predefinedBlockData: data.predefinedBlocks.map((item) => ({
        scenario: item.scenario,
        id: item.block.id,
      })),
      blockIds: data.otherBlocks.map((block) => block.id),
      tags: data.tags.map((tag) => tag.name),
    });
  };

  const handleCancel = () => {
    navigate('/admin/brands');
  };

  const defaultValues: BrandFormData = useMemo(() => {
    const predefinedBlocks = [];
    for (const item of brand.predefinedBlockData ?? []) {
      const block = brand.allBlocks?.find((block) => block.id === item.id);
      if (block) {
        predefinedBlocks.push({
          scenario: item.scenario,
          block: fromAPIBlockTypes(block),
        });
      }
    }

    const otherBlocks = [];
    for (const id of brand.blockIds ?? []) {
      const block = brand.allBlocks?.find((block) => block.id === id);
      if (block) {
        otherBlocks.push(fromAPIBlockTypes(block));
      }
    }

    return {
      name: brand?.name,
      showcaseMedia: {
        media: fromMediaDTO(brand.showcaseMedia),
        data: fromMediaDataDTO(brand.showcaseMediaData),
      },
      showcaseText: brand.showcaseText,
      blockLengthSec: brand.blockLengthSec,
      tags: brand.tags || [],
      predefinedBlocks,
      otherBlocks,
    };
  }, [brand]);

  return (
    <BrandEditor
      brandId={brand.id}
      defaultValues={defaultValues}
      action='edit'
      onConfirm={handleConfirm}
      onCancel={handleCancel}
    />
  );
}

export const Component = AdminBrandEdit;
